import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const C_API_URL = `${API_URL}/Culture`

const getTranslations = async (culture: any) : Promise<any> => {
    return axios.get(`${C_API_URL}/getAll/Code_${culture}?items_per_page=999&modelstoinclude=Translation`)
        .then((d: AxiosResponse<any>) => d.data)
}

export { getTranslations }