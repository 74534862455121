import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { getThingById, getSurveyId, getAnswersByQuestionId, getCommentsBySurveyId } from './core/_requests'
import { Survey, SurveyAnswer, SurveyAnswerList, SurveyReportQuestion, SurveyReportComment } from './core/_models'
import { ButtonGroup } from 'react-bootstrap'
import { ChartsWidgetSurvey } from '../../../_metronic/partials/widgets/charts/ChartsWidgetSurvey'

const SurveyReportPage = () => {

    const params = useParams()

    const intl = useIntl()
    const [survey, setSurvey] = useState<Survey>()
    const [surveyReportQuestion, setSurveyReportQuestion] = useState<Array<SurveyReportQuestion>>([])
    const [surveyReportComment, setSurveyReportComment] = useState<Array<SurveyReportComment>>()
    const [answerPoints, setAnswerPoints] = useState<Array<number>>()
    const [loading, setLoading] = useState(false)
    const [textReturn, setTextReturn] = useState("")
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    const [courseTermId, setCourseTermId] = useState(0)

    useEffect(() => {
        console.log('sayfaya girdi')
        if (params != undefined) {
            getSurveyId(params.QuizId).then((id: any) => {
                getThingById(id.surveyId).then((res) => {
                    setSurvey(res)
                })
                setCourseTermId(id.courseTermId)
            })
        }
    }, [params])

    useEffect(() => {
        if (survey?.answerCount != undefined) {
            const ar: Array<number> = []
            for (let i = 0; i < survey.answerCount; i++) {
                ar.push(i + 1);
            }

            setAnswerPoints(ar)

            let i = 0
            survey?.surveyGroup?.map((sg) => {
                i = i + (sg?.surveyQuestion?.length || 0)

                const sAQAr: Array<SurveyReportQuestion> = []
                sg?.surveyQuestion?.map((sq) => {
                    getAnswersByQuestionId(sq.id?.toString() || "0", courseTermId?.toString() || "0").then((res) => {                        
                        const ans: Array<number> = []
                        ar.map((ant) => {
                            ans.push(res.data.filter((ank: any) => {
                                //console.log(ank.answer)
                                return ant == ank.answer
                            }).length)
                        })
                        const sAQ = { id: sq.id || 0, name: sq.text, answers: ans }
                        sAQAr?.push(sAQ)

                        setSurveyReportQuestion([...sAQAr])
                        //console.log(sAQ)
                    })
                })
               /* console.log(sAQAr.length)
                if (sAQAr.length > 0) {

                    console.log(sAQAr)
                    setSurveyReportQuestion([...sAQAr])
                }*/
            })
            setTotal(i)

        }
    }, [survey])

    useEffect(() => {

        if ((survey?.id || 0) > 0) {
            getCommentsBySurveyId(survey?.id?.toString() || "0", courseTermId.toString()).then((res) => {
                console.log(res.data)
                setSurveyReportComment(res.data)
            })
        }
    }, [courseTermId, survey])

    return (
        <>
            {survey?.surveyGroup?.map((sg, index) => {
                return (
                    <>
                        <div className={`card mb-7`}>

                            <h1 className="header" style={{ marginLeft: '12px', marginTop: '12px', color: 'blueviolet' }}>{index + 1}. {sg.name}</h1>

                            {/* begin::User */}
                            <>
                                <div className='row'>
                                    {
                                        sg?.surveyQuestion?.map((sq, i) => {

                                            return (
                                                <>
                                                    {/* begin::Avatar 
                                                    <div className='symbol symbol-45px me-5'>{sq.id}</div>*/}
                                                    {/* end::Avatar */}

                                                    {/* begin::Info */}
                                                    <div className='col-lg-6'>
                                                        <>
                                                        <ChartsWidgetSurvey className={sq.id?.toString() || "1"} data={surveyReportQuestion?.map((a) => { return a.id == sq.id ? a.answers : undefined }).filter(a => a != undefined)[0]} categories={answerPoints} title={sq.text || ""} />

                                                        </>
                                                    </div>
                                                    {/* end::Info */}

                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        </div>
                    </>
                )
            })
            }
            {
                survey?.hasComment && (
                    <div className={`card mb-7`}>
                        {/* begin::Header */}
                        <div style={{ marginLeft: '12px', marginTop: '12px' }} className='d-flex align-items-center mb-5'>
                            {/* begin::User */}
                            <div className='d-flex align-items-center flex-grow-1'>
                                {/* begin::Avatar 
                                    <div className='symbol symbol-45px me-5'></div>*/}
                                {/* end::Avatar */}

                                {/* begin::Info */}
                                <div className='d-flex flex-column'>
                                    <span className='text-gray-800 fs-6 fw-bold'>
                                        Geri Bildirimler
                                    </span>
                                </div>
                                {/* end::Info */}
                            </div>
                            {/* end::User */}
                        </div>
                        <div style={{ marginLeft: "28px", marginBottom: "28px" }}>
                            <>
                                {
                                    surveyReportComment?.map((res) => {
                                        return <div>{res.comment}</div>
                                    })
                                }
                            </>
                        </div>
                    </div>)
            }
        </>
    )
}

export { SurveyReportPage }