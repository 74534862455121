import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    ListsWidgetUser
} from '../../../_metronic/partials/widgets'
import { useIntl } from 'react-intl'
import { getThings as getUserClasses, getUserById } from './_requests'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'


const Report: FC = () => {
    const params = useParams()
    const [classes, setClasses] = useState([])
    const [user, setUser] = useState<any>()

    const intl = useIntl()


    useEffect(() => {
        console.log('sayfaya girdi')
        if (params != undefined) {
            getUserClasses(params?.userId || "").then((res: any) => {
                setClasses(res)
            })
            getUserById(params?.userId || "").then((res: any) => {
                setUser(res)
            })
        }
    }, [params])

    return (
        <>
            <PageTitle>{intl.formatMessage({ id: 'USERCOURSEREPORTS' })}{user?.fullName}</PageTitle>
            <div className='row'>
                <div className='col-xl-12'>
                    {intl.formatMessage({ id: 'FULLNAME' })}: {user?.fullName}
                </div>
                <div className='col-xl-12'>
                    {intl.formatMessage({ id: 'USERNAME' })}: {user?.userName}
                </div>
                <div className='col-xl-12'>
                    {intl.formatMessage({ id: 'EMAIL' })}: {user?.email}
                </div>
                <div className='col-xl-12'>
                    {intl.formatMessage({ id: 'IDENTITY' })}: {user?.identity}
                </div>
            </div>
            <div className='row'>
                {
                    classes?.map((_class) => {
                        return (
                            <div className='col-xl-6'>
                                <ListsWidgetUser items={_class} className='card-xl-stretch mb-xl-8' />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export { Report }