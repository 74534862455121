import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {FC, useState} from 'react'
import { Link } from 'react-router-dom'
import {ChartsWidget} from './ChartWidget'



const DashboardPage = () => (
  <>
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-20'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/logos/logo.png')}
          alt=''
          className='mw-100 mb-10 h-lg-150px'
        />

                
        
      </div>
    </div>
    
  </>
)

const DashboardWrapper: FC = () => {

    const [reportsInfoPercentage, setReportsInfoPercentage] = useState()
    const { currentUser, setCurrentUser, saveAuth, logout } = useAuth()
    if (currentUser?.fullName == null) {
        setCurrentUser(undefined)
        saveAuth(undefined)
        //logout()
    }
    console.log(currentUser)
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
          {currentUser?.fullName}
      </PageTitle>
          {/*<DashboardPage />*/}

          <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                  {currentUser?.roleName == "Company Admin" && (<ChartsWidget reportsInfo={reportsInfoPercentage} setReportsInfo={setReportsInfoPercentage} className='card-xl-stretch mb-xl-8' />)}
              </div>
          </div>
    </EnableSidebar>
  )
}

export {DashboardWrapper}
