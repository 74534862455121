import React, { useState, useEffect } from 'react';
import { Document, Page, Outline } from 'react-pdf';

interface Props {
    lfButton: boolean;
    fileUrl: string;
    setLessonPosition: Function;
    lessonPosition: number;
    setLessonFinish: Function;
}

const PdfLayout: React.FC<Props> = ({ lfButton, fileUrl, setLessonPosition, lessonPosition, setLessonFinish }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(1100);


    useEffect(() => {
        setPageNumber(lessonPosition)
    }, [lessonPosition])

    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        setNumPages(numPages);
    }
    function changePage(offset: any) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);        
    }
    
    function previousPage() {
        changePage(-1);
        setLessonPosition(pageNumber-1);
    }

    function nextPage() {
        changePage(1);
        setLessonPosition(pageNumber+1);
    }

    function changeZoom(offset: any) {
        setZoom(prevZoom => prevZoom + offset);
    }

    function zoomOut() {
        changeZoom(-100);
    }

    function zoomIn() {
        changeZoom(100);
    }

    return (
        <>
            <div style={{ bottom:'20px', left:'50%', position: 'fixed', zIndex:'999', textAlign: 'center' }}>
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                </p>
                <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary me-2'
                >
                     <i className="las la-angle-double-left" style={{fontSize: '24px'}}></i>
                </button>
               
                <button
                    type="button"
                    disabled={zoom <= 800}
                    onClick={zoomOut}
                    className='btn btn-sm btn-outline btn-outline-dashed btn-outline-info me-2'
                >
                    <i className="las la-search-minus" style={{fontSize: '24px'}}></i>
                </button>
                <button
                    type="button"
                    disabled={zoom >= 1600}
                    onClick={zoomIn}
                    className='btn btn-sm btn-outline btn-outline-dashed btn-outline-info me-2'
                >
                    <i className="las la-search-plus" style={{fontSize: '24px'}}></i>
                </button>
                <button
                    type="button"
                    disabled={pageNumber >= (numPages || 0)}
                    onClick={nextPage}
                    className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary'
                >
                    <i className="las la-angle-double-right" style={{fontSize: '24px'}}></i>
                </button>
                {pageNumber == numPages && lfButton &&
                (<button
                    type='button'
                    style={{ width: "200px", position: "fixed", right: "175px" }}
                    disabled={lessonPosition < 1}
                    className='btn btn-sm btn-dark btn-active-light-dark me-2'
                    onClick={() => setLessonFinish()}
                >
                    {'Finish Lesson'}
                </button>)}
            </div>

            <Document  file={ fileUrl } onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} renderTextLayer={false} width={zoom} />               
            </Document>            
        </>
    );
}

export default PdfLayout;