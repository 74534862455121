import React, {FC, useEffect} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/tr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'


import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/tr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import { WithChildren } from '../helpers'
import {getTranslations} from './getTranslationsFromDatabase'
import { useState } from 'react'

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  tr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]
    const [msgs, setMsgs] = React.useState<Record<string, string>>()

    useEffect(() => {
        async function loadData() {
            getTranslations(locale).then((res) => {
                console.log(res)
                const strings = res.data[0]?.translation.map((translations: any) => {
                    return { [translations.key]: translations.value }
                })
                console.log(Object.assign({}, ...strings))
                setMsgs(Object.assign({}, ...strings))
            })
        }
        loadData()
        //setMsgs(allMessages[locale])
        console.log(msgs)
    }, [])

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
