import axios, {AxiosResponse} from 'axios'
import {ID,parseRequestQuery,  Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Library`
const LT_THING_URL = `${API_URL}/LibraryTenant`
const ADD_THING_URL = `${API_URL}/Custom/addLibrary`

const getThings = (query: string): Promise<QueryResponse> => {
  return axios
    .get(`${THING_URL}/getAll/?${query}&istenantactive=false`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listThings2 = async (lctid: any): Promise<any> => await axios.get(`${THING_URL}/getAll/LibraryCategoryId-${lctid}?items_per_page=9999&istenantactive=false`).then((res: AxiosResponse) => {
    return res.data;
});

const listThings = async (tenantId: number): Promise<any> => {
    let date = new Date()
    let dty = date.getFullYear() - 1
    let dtm = date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)
    let dtd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
    const ret = await axios.get(`${LT_THING_URL}/getAll/LibraryTenantId-${tenantId}|(Date)CreatedAt>${dty}.${dtm}.${dtd}?page=1&items_per_page=9999&istenantactive=false&modelstoinclude=Library`).then((res: AxiosResponse) => {
        return res.data
    })
    return ret
}

const getThingById = (id: ID): Promise<Model | undefined> => {
  return axios
    .get(`${THING_URL}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const createThing = (thing: any): Promise<Model | undefined> => {
  return axios
  .put(ADD_THING_URL, thing, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const updateThing = (thing: Model): Promise<Model | undefined> => {
  return axios
    .post(`${THING_URL}/${thing.id}`, thing)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteThing = (thingId: ID): Promise<void> => {
  return axios.delete(`${THING_URL}/${thingId}`).then(() => {})
}

const deleteSelectedThings = (thingIds: Array<ID>): Promise<void> => {
  const requests = thingIds.map((id) => axios.delete(`${THING_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getThings,
  deleteThing,
  deleteSelectedThings,
  getThingById,
  createThing,
  updateThing,
    listThings, listThings2
}
