import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {FC, useState} from 'react'
import { Link } from 'react-router-dom'
import { Charts } from '../../modules/widgets/components/Charts'



const ReportsWrapper: FC = () => {

    const [reportsInfoPercentage, setReportsInfoPercentage] = useState()
    const { currentUser, setCurrentUser, saveAuth, logout } = useAuth()
    if (currentUser?.fullName == null) {
        setCurrentUser(undefined)
        saveAuth(undefined)
        //logout()
    }
    console.log(currentUser)
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
          {currentUser?.fullName}
      </PageTitle>
          {/*<DashboardPage />*/}

          <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                 <Charts />
              </div>
          </div>
    </EnableSidebar>
  )
}

export { ReportsWrapper }
