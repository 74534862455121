import axios, {AxiosResponse} from 'axios'
import {ID,parseRequestQuery,  Response} from '../../../../_metronic/helpers'
import { Survey, SurveyAnswerList} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Survey`
const ANSWER_THING_URL = `${API_URL}/SurveyAnswer`
const COMMENT_THING_URL = `${API_URL}/SurveyComment`
const GET_SURVEY_ID_URL = `${API_URL}/Custom/getSurveyId`

const getThingById = (id: string): Promise<Survey | undefined> => {
  return axios
    .get(`${THING_URL}/${id}?modelstoinclude=SurveyGroup.SurveyQuestion`)
      .then((response: AxiosResponse<Response<Survey>>) => response.data)
      .then((response: Response<Survey>) => response as any)
}

const getAnswersByQuestionId = (id: string, ctid: string): Promise<any> => {
  return axios
      .get(`${ANSWER_THING_URL}/getAll/SurveyQuestionId-${id}|CourseTermId-${ctid}?select=Answer&items_per_page=9999`)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      .then((response: Response<any>) => response as any)
}

const getCommentsBySurveyId = (id: string, ctid: string): Promise<any> => {
    return axios
        .get(`${COMMENT_THING_URL}/getAll/SurveyId-${id}|CourseTermId-${ctid}?select=Comment&items_per_page=9999`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response as any)
}

const getSurveyId = (QuizId: any): Promise<any> => {
    return axios
        .get(`${GET_SURVEY_ID_URL}?Id=${QuizId}`)
        .then((res: AxiosResponse) => {
            return res.data
        })
}

export {
    getThingById, getSurveyId, getAnswersByQuestionId, getCommentsBySurveyId
}
