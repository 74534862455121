import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
    ChartsWidget1
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {FC, useState} from 'react'
import { Link } from 'react-router-dom'

const VisualReportsWrapper: FC = () => {

    const [reportsInfoPercentage, setReportsInfoPercentage] = useState()
    const { currentUser, setCurrentUser, saveAuth, logout } = useAuth()
    if (currentUser?.fullName == null) {
        setCurrentUser(undefined)
        saveAuth(undefined)
        //logout()
    }
    console.log(currentUser)
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
          {currentUser?.fullName}
      </PageTitle>
          {/*<DashboardPage />*/}
          <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                  <ChartsWidget1 className='card-xl-stretch mb-xl-8' />
              </div>
          </div>
    </EnableSidebar>
  )
}

export { VisualReportsWrapper }
