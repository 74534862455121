import axios, { AxiosResponse } from 'axios'
import { ID, parseRequestQuery, Response } from '../../../_metronic/helpers'
import { Model, QueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Custom/getUserClasses`
const USER_URL = `${API_URL}/Auth/get-user-by-id`

const getThings = (userId: string): Promise<QueryResponse> => {
    return axios
        .get(`${THING_URL}?userId=${userId}`)
        .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getUserById = (userId: string): Promise<QueryResponse> => {
    return axios
        .post(`${USER_URL}?userId=${userId}`)
        .then((d: AxiosResponse<QueryResponse>) => d.data)
}

export {
    getThings, getUserById
}
