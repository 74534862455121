import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { getThingById, addSurveyAnswer, getSurveyId } from './core/_requests'
import { Survey, SurveyAnswer, SurveyAnswerList } from './core/_models'
import { ButtonGroup } from 'react-bootstrap'

const MySurveyPage = () => {

    const params = useParams()

    const intl = useIntl()

    const [survey, setSurvey] = useState<Survey>()
    const [answerPoints, setAnswerPoints] = useState<Array<number>>()
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<Array<SurveyAnswer>>([])
    const [textReturn, setTextReturn] = useState("")
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    const [courseTermId, setCourseTermId] = useState()

    useEffect(() => {
        console.log('sayfaya girdi')
        if (params != undefined) {
            getSurveyId(params.QuizId).then((id: any) => {
                getThingById(id.surveyId).then((res) => {
                    setSurvey(res)
                })
                setCourseTermId(id.courseTermId)
            })
        }
    }, [params])

    useEffect(() => {
        if (survey?.answerCount != undefined) {
            const ar = []
            for (let i = 0; i < survey.answerCount; i++) {
                ar.push(i + 1);
            }

            setAnswerPoints(ar)

            let i = 0
            survey?.surveyGroup?.map((sg) => {
                i = i + (sg?.surveyQuestion?.length || 0)
            })

            setTotal(i)
        }
    }, [survey])

    const handleSendSurvey = () => {
        const salist: SurveyAnswerList = {
            surveyAnswers: options,
            answerText: textReturn,
            courseTermId: courseTermId
        }

        addSurveyAnswer(salist).then(() => {
            navigate(`/my-quiz`)
        })
    }

    const handleRatingClick = (id?: number, rating?: number) => {

        let index = options.findIndex((option) => option.surveyQuestionId === id)
        if (index > -1) {
            options[index].answer = rating
        }
        else {
            options.push({
                surveyQuestionId: id,
                answer: rating
            })
        }
        setOptions([...options])

        console.log('id: ', id, ', rating: ', rating);
        console.log('textoption: ', textReturn)

    }

    return (
        <>
            {survey?.surveyGroup?.map((sg, index) => {
                return (
                    <>
                        <div className={`card mb-7`}>

                            <h1 className="header" style={{ marginLeft: '12px', marginTop: '12px', color: 'blueviolet' }}>{index+1}. {sg.name}</h1>
                            {
                                sg?.surveyQuestion?.map((sq, i) => {

                                    return (
                                        <>
                                            {/* begin::Header */}
                                            <div style={{ marginLeft: '12px', marginTop: '12px' }} className='d-flex align-items-center mb-5'>
                                                {/* begin::User */}
                                                <div className='d-flex align-items-center flex-grow-1'>
                                                    {/* begin::Avatar 
                                                    <div className='symbol symbol-45px me-5'>{sq.id}</div>*/}
                                                    {/* end::Avatar */}

                                                    {/* begin::Info */}
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-gray-800 fs-6 fw-bold'>
                                                            {index + 1}.{ i+1 }. {sq.text}
                                                        </span>
                                                    </div>
                                                    {/* end::Info */}
                                                </div>
                                                {/* end::User */}
                                            </div>
                                            {/* end::Header */}
                                            {/* begin::Post */}
                                            {
                                                <div>
                                                    <>
                                                        <ButtonGroup style={{ marginLeft: "28px", marginBottom: "28px" }} size="sm"  > {
                                                            answerPoints?.map((ap) => {
                                                                return (<button onClick={(e) => { handleRatingClick(sq.id || 0, ap) }} className={options[options.findIndex((option) => option.surveyQuestionId === sq.id)]?.answer == ap ? "btn btn-primary" : "btn btn-secondary"
                                                                }> {ap}</button>)
                                                            })
                                                        }
                                                        </ButtonGroup>
                                                    </>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            })
            }
            {
                survey?.hasComment && (
                    <div className={`card mb-7`}>
                        {/* begin::Header */}
                        <div style={{ marginLeft: '12px', marginTop: '12px' }} className='d-flex align-items-center mb-5'>
                            {/* begin::User */}
                            <div className='d-flex align-items-center flex-grow-1'>
                                {/* begin::Avatar 
                                    <div className='symbol symbol-45px me-5'></div>*/}
                                {/* end::Avatar */}

                                {/* begin::Info */}
                                <div className='d-flex flex-column'>
                                    <span className='text-gray-800 fs-6 fw-bold'>
                                        Geri Bildirimleriniz
                                    </span>
                                </div>
                                {/* end::Info */}
                            </div>
                            {/* end::User */}
                        </div>
                        <div style={{ marginLeft: "28px", marginBottom: "28px" }}>
                            <>
                                <textarea
                                    rows={3}
                                    name='answertext'
                                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                                    autoComplete='off'
                                    disabled={loading}
                                    value={textReturn}
                                    onChange={(e) => setTextReturn(e.target.value)}
                                />
                            </>
                        </div>
                    </div>)
            }
            <div className='pb-0'>
                {/* begin::Header */}
                <div style={{ marginTop: "40px" }} className='align-items-center mb-5'>
                </div>
                <div>
                    <button
                        type="button"
                        disabled={options.length < total || loading}
                        className="btn btn-warning" onClick={handleSendSurvey}
                    >
                        Anketi Tamamla
                    </button>
                </div>
            </div>
        </>
    )
}

export { MySurveyPage }