/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { getPercentageReport } from '../quiz/list/core/_requests'

type Props = {
    className: string,
    reportsInfo: any,
    setReportsInfo: any
}

const ChartsWidget: React.FC<Props> = ({ className, reportsInfo, setReportsInfo }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()


    useEffect(() => {
        const chart = refreshChart(reportsInfo)

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, reportsInfo])

    useEffect(() => {
        getPercentageReport("").then((res: any) => {
            setReportsInfo([...res?.data])
        })
    }, [])

    const refreshChart = (reportsInfo: any) => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, reportsInfo))
        if (chart) {
            chart.render()
        }

        return chart
    }

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Yuzde Raporu / Percentage Report</span>

                    {/* <span className='text-muted fw-semibold fs-7'>This month</span> */}
                </h3>
                {/* end::Title */}

                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ChartsWidget }

function getChartOptions(height: number, reportsInfo: any): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-primary')
    const secondaryColor = getCSSVariableValue('--kt-gray-300')
    const fourthColor = getCSSVariableValue('--kt-warning')
    const fifthColor = getCSSVariableValue('--kt-danger')
    const thirdColor = getCSSVariableValue('--kt-info')

    const series: any = []
    const categories: any = []
    if (reportsInfo?.length >= 1) {


        reportsInfo?.map((dat: any) => {
            categories.push(dat?.name)
            // })
        })

        /*
        "finished": 0,
        "notStarted": 100,
        "inProgress": 0,
        "cancelled": 0,
        */
        series.push({
            name: 'Bitmiş',
            data: [
                reportsInfo[0]?.finished || 0,
                reportsInfo[1]?.finished || 0,
            ],
        })
        series.push({
            name: 'Henüz Başlamayan',
            data: [
                reportsInfo[0]?.notStarted || 0,
                reportsInfo[1]?.notStarted || 0,
            ],
        })
        series.push({
            name: 'Devam Eden',
            data: [
                reportsInfo[0]?.inProgress || 0,
                reportsInfo[1]?.inProgress || 0,
            ],
        })
        series.push({
            name: 'Toplam',
            data: [
                reportsInfo[0]?.total || 0,
                reportsInfo[1]?.total || 0,
            ],
        })        
    }
    return {
        series: series,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' %'
                },
            },
        },
        colors: [baseColor, secondaryColor, thirdColor, fourthColor, fifthColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
