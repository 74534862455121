import { useMemo, useEffect, useState } from 'react'
import { useTable } from 'react-table'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { KTSVG, QUERIES } from '../../../_metronic/helpers'
import { useQueryResponseData, useQueryResponseLoading } from './list/core/QueryResponseProvider'
import { Columns } from './list/table/columns/_columns'
import { useQuery } from 'react-query'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
    listThings, addStartQuiz, addQuizQuestionAnswer, getQuizQuestionForAnswer, addVisualQuizQuestionAnswer
} from './list/core/_requests'
import {
    getVisualUrl
} from '../question-bank/list/core/_requests'
import { Model, QuizQuestionModel } from './list/core/_models'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import Swal from 'sweetalert2'
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';

const QuizQuestionsForm = () => {
    const items = useQueryResponseData()
    const [markers, setMarkers] = useState<Array<Marker>>([]);
    const CustomMarker = (props: MarkerComponentProps) => {
        return (
            <span style={{ height: '8px', width: '8px', backgroundColor:'red', borderRadius:'50%',display:'inline-block'}}></span>
        )
    }
    const params = useParams()
    //console.log(params)
    const intl = useIntl()

    const [quizQuestion, setQuizQuestion] = useState <QuizQuestionModel>()
    const [activeOption, setActiveOption] = useState("")
    
    const [questionNumber, setQuestionNumber] = useState(1)
    const [timeLeft, setTimeLeft] = useState(9999)
    const [loading, setLoading] = useState(false)
    const [hasThread, setHasThread] = useState(false)
    const [imgUrl, setImgUrl] = useState("")
    const navigate = useNavigate()
    const [libraryId, setLibraryId] = useState(0)
    const [height, setHeight] = useState(500)
    const [isLastQuestion, setIsLastQuestion] = useState(false)

    useEffect(() => {
        if (params?.QuizId) {

            addStartQuiz(params.QuizId).then((res) => {
                if (JSON.parse(res).result == "alreadyStarted") {
                    navigate("/dashboard")
                }
            })

            getQuizQuestionForAnswer(params.QuizId).then((res) => {
                try {
                    let c = JSON.parse(res)
                    if (c.result == "finished") {
                        navigate("/my-quiz/list")
                    }
                }
                catch
                {

                }
                console.log("qq")
                console.log(res.quizQuestions)

                setQuizQuestion(res.quizQuestions)
                setIsLastQuestion(res.isFinalQuestion)
                res.quizQuestions.question.questionType == 3 ? setTimeLeft(25000) : setTimeLeft(res.quizQuestions.quiz.duration*60)
                if (res.quizQuestions.question.questionType == 3) {
                    setHeight(500)
                    //setLibraryId(res.question.libraryId)
                    getVisualUrl(parseInt(res.quizQuestions.question.id || "0"), "original").then((res2) => {
                        setImgUrl(res2)
                    })
                }
            })
        }
        console.log(quizQuestion)
    }, [params])

    const handleOnClickOption = (e: any) => {
        //setLoading(true)
        //console.log("setted optid: ", e.currentTarget.value)
        setActiveOption(e.currentTarget.value)
        //console.log(e.currentTarget.value)
        //setLoading(false)
    }

    const handleTextOption = (e: any) => {
        //setLoading(true)
        setActiveOption(e.target.value)
       // console.log(e.target.value)
        //setLoading(false)
    }

    const handleDivClick = (e: any) => {
       // console.log(e.clientX, e.clientY)

    }

    const handleDivMouseDown = (e: any) => {
       console.log(e.clientX, e.clientY)

        if (e.clientX > 162 && e.clientX < 179 && e.clientY > 90 && e.clientY < 110) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p1").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 196 && e.clientX < 214 && e.clientY > 90 && e.clientY < 110) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p2").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 228 && e.clientX < 248 && e.clientY > 90 && e.clientY < 110) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p3").then((res2) => {
                setImgUrl(res2)
            })
        }


        if (e.clientX > 309 && e.clientX < 329 && e.clientY > 153 && e.clientY < 175) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p2").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 349 && e.clientX < 369 && e.clientY > 153 && e.clientY < 175) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p1").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 394 && e.clientX < 414 && e.clientY > 153 && e.clientY < 175) {
            getVisualUrl(quizQuestion?.question?.id || 0, "p3").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 309 && e.clientX < 329 && e.clientY > 183 && e.clientY < 205) {
            getVisualUrl(quizQuestion?.question?.id || 0, "bw").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 349 && e.clientX < 369 && e.clientY > 183 && e.clientY < 205) {
            getVisualUrl(quizQuestion?.question?.id || 0, "s-en").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 394 && e.clientX < 414 && e.clientY > 183 && e.clientY < 205) {
            getVisualUrl(quizQuestion?.question?.id || 0, "inv").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 309 && e.clientX < 329 && e.clientY > 221 && e.clientY < 243) {
            getVisualUrl(quizQuestion?.question?.id || 0, "hi").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 349 && e.clientX < 369 && e.clientY > 221 && e.clientY < 243) {
            getVisualUrl(quizQuestion?.question?.id || 0, "o2").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 394 && e.clientX < 413 && e.clientY > 221 && e.clientY < 243) {
            getVisualUrl(quizQuestion?.question?.id || 0, "os").then((res2) => {
                setImgUrl(res2)
            })
        }

        if (e.clientX > 142 && e.clientX < 160 && e.clientY > 140 && e.clientY < 156) {
            height > 200 ? setHeight(height - 100) : setHeight(height)
        }

        if (e.clientX > 255 && e.clientX < 275 && e.clientY > 140 && e.clientY < 156) {
            height < 1000 ? setHeight(height + 100) : setHeight(height)
        }

        if (e.clientX > 255 && e.clientX < 275 && e.clientY > 224 && e.clientY < 241) {
            setHeight(500)
            getVisualUrl(quizQuestion?.question?.id || 0, "original").then((res2) => {
                setImgUrl(res2)
            })
        }
    }

    const nextQuestion = async () => {
        setLoading(true)
        setQuizQuestion(undefined)
        setQuestionNumber(questionNumber+1)
        if (quizQuestion?.question.questionType != 3) {
            addQuizQuestionAnswer(quizQuestion?.id, activeOption).then(() => {
                getQuizQuestionForAnswer(params.QuizId).then((res) => {
                    try {
                        let ck = JSON.parse(res)
                        if (ck.result == "finished") {
                            navigate("/my-quiz/list")
                        }
                    }
                    catch
                    {

                    }
                    //console.log(res.quizQuestions)
                    setQuizQuestion(res.quizQuestions)
                    setIsLastQuestion(res.isFinalQuestion)
                    setActiveOption("")
                })
            })
        }
        if (quizQuestion?.question.questionType == 3) {

            setMarkers([])
            setActiveOption("")
            setHasThread(false)
           // console.log(markers)
            let left = markers[0] != undefined ? (Math.round(5 * (Number(markers[0].left))+4).toString()).toString() : ""
            let top = markers[0] != undefined ? (Math.round(5*(Number(markers[0].top)))+8).toString() : ""

                addVisualQuizQuestionAnswer(quizQuestion?.id, activeOption, left + "," + top, hasThread).then(() => {                
                    getQuizQuestionForAnswer(params.QuizId).then((res) => {

                        try {
                            let c = JSON.parse(res)
                            if (c.result == "finished") {
                                navigate("/my-quiz/list")
                            }
                        }
                        catch
                        {

                        }
                 //   console.log(res.quizQuestions)
                    setQuizQuestion(res.quizQuestions)
                    setIsLastQuestion(res.isFinalQuestion)
                    setLibraryId(res.quizQuestions.question.libraryId)
                    getVisualUrl(res.quizQuestions.question.id, "original").then((res2) => {
                        setImgUrl(res2)
                    })                    
                })
            })
            setTimeLeft(25000)
        }

        setActiveOption("")
        setLoading(false)
    }

    useEffect(() => {
        if (timeLeft < 9995 && timeLeft > 0) {
        const interval = setInterval(() => {
            setTimeLeft(timeLeft - 1)
            //console.log(timeLeft)
        }, 1000);
            return () => clearInterval(interval);
        }
        if (timeLeft <= 0) {
          //  console.log('finish')
        }
        if (timeLeft == 0 && quizQuestion?.question.questionType == 0) {
            navigate("/dashboard")
        }
        if (timeLeft == 0 && quizQuestion?.question.questionType == 3) {
            nextQuestion()
        }

        console.log(quizQuestion)
    }, [timeLeft]);

    return (
        <>

            {quizQuestion ? (<div>
                <div style={{ marginLeft: `600px` }} className={`card mb-7`}>
                    {/* begin::Body */}
                    <div className='card-body pb-0'>
                        {quizQuestion?.question.questionType == 3 ? (

                            <div style={{ width: `${height}px`, height: `${height}px`, marginBottom: '30px', border: '1px solid' }} >
                                <ImageMarker
                                    src={imgUrl}
                                    markers={markers}
                                    onAddMarker={(marker: Marker) => hasThread && quizQuestion.question.hasSign && setMarkers([marker])}
                                    markerComponent={CustomMarker}
                                    bufferLeft={4}
                                    bufferTop={8}
                                />
                            </div>
                        ) : ""
                        }

                    </div>
                    {/* end::Body */}
                </div>
                <div style={{ textAlign: "center", position: 'fixed', top: '0px', border: '1px solid', marginTop: quizQuestion?.question.questionType == 3 ? '0px' : '100px', marginLeft: quizQuestion?.question.questionType == 3 ? '0px' : '-500px', left: quizQuestion?.question.questionType == 3 ? '0px' : '50%', width: quizQuestion?.question.questionType == 3 ? '600px' : '1000px', height: quizQuestion?.question.questionType == 3 ? `100%` : '600px', background: '#eff2f5' }}>
                    {quizQuestion?.question.questionType == 3 && (<div onClick={handleDivClick} onMouseDown={handleDivMouseDown} style={{ width: "595px", height: "288px", marginTop: "20px", backgroundImage: "url(/media/quiz/klavye.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}></div>)}

                    {loading == false && (
                        <div className={`card mb-7`}>
                            {/* begin::Header */}
                            <div style={{ marginLeft: '12px', marginTop: '12px' }} className='d-flex align-items-center mb-5'>
                                {/* begin::User */}
                                <div className='d-flex align-items-center flex-grow-1'>
                                    {/* begin::Avatar */}
                                    <div className='symbol symbol-45px me-5'>{questionNumber}</div>
                                    {/* end::Avatar */}

                                    {/* begin::Info */}
                                    <div className='d-flex flex-column'>
                                        <span className='text-gray-800 fs-6 fw-bold'>
                                            {quizQuestion?.question.text}
                                        </span>
                                    </div>
                                    {/* end::Info */}
                                </div>
                                {/* end::User */}
                            </div>
                            {/* end::Header */}
                            {/* begin::Post */}
                            <>
                                <div className='mb-5' style={{ marginLeft: '20px' }}>
                                    <div className='row mb-3'>

                                        {/* begin::Text */}
                                        {quizQuestion?.question.questionType == 0 ? quizQuestion?.question.answerTemplateOption?.map((opt: any, i: any) => {
                                            return (

                                                <div className='col-lg-12 fv-row'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <label className='form-check form-check-inline form-check-solid me-5'>
                                                            <input
                                                                className='form-check-input'
                                                                name={`qa`}
                                                                onChange={handleOnClickOption}
                                                                type='radio'
                                                                value={opt?.id} checked={activeOption == opt?.id}
                                                            />
                                                            <span className='fw-bold ps-2 fs-6'>{opt?.optionName}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                            quizQuestion?.question.questionType == 1 && quizQuestion?.question.AnswerTemplate?.answerTemplateOption?.map((opt: any, i: any) => {
                                                return (

                                                    <div className='col-lg-1 fv-row'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <label className='form-check form-check-inline form-check-solid me-5'>
                                                                <input
                                                                    className='form-check-input'
                                                                    name={`qa`}
                                                                    onChange={handleOnClickOption}
                                                                    type='radio'
                                                                    value={opt?.id} checked={activeOption == opt?.id}
                                                                />
                                                                <span className='fw-bold ps-2 fs-6'>{opt?.optionName}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            quizQuestion?.question.questionType == 2 ? (
                                                <div>
                                                    <label className='required fw-bold fs-6 mb-2'>
                                                        {intl.formatMessage({ id: 'QUIZ.ANSWERTEXT' })}
                                                    </label>

                                                    <textarea
                                                        rows={3}
                                                        name='answertext'
                                                        className={'form-control form-control-solid mb-3 mb-lg-0'}
                                                        autoComplete='off'
                                                        disabled={loading}
                                                        value={activeOption}
                                                        onChange={handleTextOption}
                                                    />
                                                </div>) : (<></>)
                                        }
                                        {
                                            quizQuestion?.question.questionType == 3 ? (
                                                <>

                                                    <div className='row mb-3'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            {intl.formatMessage({ id: 'QUESTION.HASTHREAD' })}
                                                        </label>
                                                        <div className='col-lg-8 d-flex align-items-center'>
                                                            <div className='form-check form-check-solid form-switch fv-row'>
                                                                <input
                                                                    checked={hasThread}
                                                                    onChange={(e) => { setHasThread(e.target.checked); setMarkers([]) }}
                                                                    value={hasThread ? 'on' : 'off'}
                                                                    className='form-check-input w-40px mt-2 border-secondary'
                                                                    type='checkbox'
                                                                    id='hasThread' />
                                                                {quizQuestion.question.hasSign && hasThread && (<label className='form-check-label mt-3 px-5'><small className='text-danger'>{intl.formatMessage({ id: 'QUESTION.HASTHREAD.TEXT' })}</small></label>)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        hasThread && quizQuestion?.question.answerTemplateOption?.map((opt: any) => {
                                                            return (

                                                                <div style={{ marginLeft: '12px', marginTop: '12px' }} className='col-lg-4 fv-row'>
                                                                    <div className='d-flex align-items-center mt-3'>
                                                                        <label className='form-check form-check-inline form-check-solid me-5'>
                                                                            <input
                                                                                className='form-check-input'
                                                                                name={`qa`}
                                                                                onChange={handleOnClickOption}
                                                                                type='radio'
                                                                                value={opt?.id} checked={activeOption == opt?.id}
                                                                            />
                                                                            <span className='fw-bold ps-2 fs-6'>{opt?.optionName}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            ) : (<></>)
                                        }
                                    </div>
                                </div>
                            </>
                        </div>)}
                    <div className='pb-0'>
                        {/* begin::Header */}
                        <div style={{ marginTop: "40px" }} className='align-items-center mb-5'>
                            {/* begin::User */}

                            <span className="alert alert-success">Kalan Süre: {Math.floor(timeLeft / 60)} Dakika, {timeLeft % 60} Saniye</span>
                        </div>
                        <div>
                            <button
                                type="button"
                                disabled={timeLeft <= 0 || loading}
                                onClick={nextQuestion}
                                className="btn btn-warning"
                            >
                                {intl.formatMessage({ id: isLastQuestion ? 'QUIZ.FINISHEXAM' : 'QUIZ.NEXTQUESTION' })}
                            </button> </div>

                    </div>
                </div>
            </div>) : <div>
                <div style={{ textAlign: "center", position: 'fixed', top: '0px', marginTop: "300px", marginLeft:"auto", width: '100%', height: `100%` }}>
                        <span className="indicator-progress" style={{ display: "block" }} data-kt-indicator='on'>
                            <span className="spinner-border spinner-border-xl align-middle ms-2"></span>
                        </span>
                </div>
            </div>}
            
    </>
    )
}

export { QuizQuestionsForm }